
body {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

.ant-layout {
  background: #fff !important;
}

.mainLayout .ant-layout-header {
  background: rgb(253, 250, 250);
  padding: 0;
  height: auto;
  line-height: 1.6;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

.bgGray {
  background: #fafafa;
}

/* 
title holder
------------
*/
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: '';
  background: #1890ff;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 28px;
  margin: 0;
}

/* 
header
------ 
*/
.ant-layout-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}
.ant-drawer-content-wrapper{
  width: 60%!important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0;
}

.header .logo {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
}
.header .logo .logosub{
  font-size: 12px;
  /* text-transform: lowercase; */
  font-weight: 200;
}


.header .logo .fas {
  color: #1890ff;
  margin: 0 5px 0 0;
  font-size: 28px;
}

.header .logo a {
  color: #1890ff;
}

.header .ant-anchor-wrapper {
  background: none;
}

.header .ant-anchor {
  display: flex;
}

.header .ant-anchor-ink {
  display: none;
}

.header .ant-anchor-link {
  padding: 0 20px;
  line-height: 1.4;
  position: relative;
}

.header .ant-anchor-link:before,
.header .ant-anchor-link:after {
  content: '';
  background: #1890ff;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-anchor-link:after {
  left: auto;
  right: 30%;
}

.header .ant-anchor-link.ant-anchor-link-active:before,
.header .ant-anchor-link:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-anchor-link.ant-anchor-link-active:after,
.header .ant-anchor-link:hover:after {
  right: 5%;
  opacity: 1;
}

/* ant menu */
.header .ant-menu {
  border: 0;
  background: none !important;
}

.header .ant-menu li.ant-menu-item {
  position: relative;
  color: #333;
}

.header .ant-menu li.ant-menu-item:before,
.header .ant-menu li.ant-menu-item:after {
  content: '';
  background: #1890ff;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-menu li.ant-menu-item:after {
  left: auto;
  right: 30%;
}

.header .ant-menu li.ant-menu-item,
.header .ant-menu li.ant-menu-item-selected {
  border: 0 !important;
}

.header .ant-menu li.ant-menu-item-selected,
.header .ant-menu li.ant-menu-item:hover {
  color: #1890ff;
  background: none;
}

.header .ant-menu li.ant-menu-item-selected:before,
.header .ant-menu li.ant-menu-item:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-menu li.ant-menu-item-selected:after,
.header .ant-menu li.ant-menu-item:hover:after {
  right: 5%;
  opacity: 1;
}

/* 
hero
---- 
*/
.heroBlock {
  /* background: url('assets/images/bg-hero1.jpg') no-repeat; */
  background: url('assets/images/bg-hero3_1.jpg') no-repeat;
  /* background-position:100% 5%; */
  background-size: cover;
  height: 100vh;
}

.heroBlock .container-fluid {
  display: block !important;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
}

.heroBlock .slick-dots li button {
  background: #000 !important;
}

.heroBlock .slick-dots li.slick-active button {
  background: #1890ff !important;
}

.heroBlock .content {
  max-width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 70px;
}

.heroBlock h3 {
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 30px;
  animation: slideInFromTop 0.5s ease-in;
}

.heroBlock p {
  margin: 0 0 30px;
  animation: slideInFromTop 0.5s ease-in;
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .btnHolder button,
.heroBlock .btnHolder .fas {
  margin: 0 10px 0 0;
}

/* 
block
----- 
*/
.block {
  padding: 60px 0;
  
}

/* 
about
----- 
*/
.aboutBlock .contentHolder {
  text-align: center;
  margin: 0 0 50px;
}

.aboutBlock .icon {
  font-size: 40px;
  color: #1890ff;
  margin: 0 0 10px;
}

.aboutBlock .content {
  text-align: center;
}

/* 
how it works
------------
*/
.worksBlock {
  background: url('assets/images/bg5.jpg') no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}

.worksBlock:before {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.worksBlock h2 {
  color: #fff;
}

.worksBlock .contentHolder {
  text-align: center;
}

.worksBlock .contentHolder button {
  font-size: 40px;
  line-height: 1;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  padding: 30px;
  border-radius: 50%;
  width: 110px;
  height: 110px;
}

/* 
faqs
----
*/
.faqBlock .ant-collapse {
  margin: 0 0 40px;
}

.faqBlock .quickSupport {
  text-align: center;
  max-width: 740px;
  margin: auto;
}

.faqBlock .fas {
  margin: 0 10px 0 0;
}

/* 
pricing block
-------------
*/
.pricingBlock .ant-list {
  text-align: center;
}

.pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
  box-shadow: 0 0 12px 2px #f3f3f3;
  transform: scale(1.1);
  position: relative;
  z-index: 1;
}

.pricingBlock .ant-card-head-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
}

.pricingBlock .large {
  font-size: 38px;
  line-height: 1;
  margin: 0 0 20px;
}

.pricingBlock .fab {
  margin: 0 10px 0 0;
}

/* 
contact
-------
*/
/* .contactBlock .ant-form {
  max-width: 640px;
  margin: auto;
}

.contactBlock .ant-form textarea {
  min-height: 120px;
  height: 120px;
} */
.contactBlock{
  display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #fafafa;
}
.contact-form {
  
  width: 80vw;
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin: 50px 0;
}
.contact-form > * {
  width: 50%;
}
.contact-form .first-container {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("https://colorlib.com/etc/cf/ContactFrom_v17/images/bg-01.jpg") center center / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-form .first-container .info-container div {
  margin: 24px 0;
}
.contact-form .first-container .info-container div h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 10px;
}
.contact-form .first-container .info-container div:first-of-type p {
  max-width: 260px;
  color: #999;
}
.contact-form .first-container .info-container div p {
  font-size: 16px;
  line-height: 1.6;
  color: #1890ff;
}
.contact-form .second-container {
  padding: 30px;
}
.contact-form .second-container h2 {
  font-size: 30px;
  font-weight: 400;
  color: #333;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 20px;
}
.contact-form .second-container form {
  display: flex;
  flex-direction: column;
}
.contact-form .second-container form .form-group {
  margin-bottom: 10px;
}
.contact-form .second-container form .form-group * {
  min-height: 55px;
  border: 1px solid #e6e6e6;
  padding: 0 20px;
}
.contact-form .second-container form .form-group label {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e6e6e6;
  font-size: 16px;
  color: #333;
  text-transform: uppercase;
  margin-top: -1px;
}
/* .contact-form .second-container form .form-group:first-of-type input {
  width: 50.1%;
  margin-right: -5px;
} */
.contact-form .second-container form .form-group input {
  width: 100%;
  font-size: 15px;
  margin-top: -2px;
}
.contact-form .second-container form .form-group input::placeholder, .contact-form .second-container form .form-group textarea::placeholder {
  color: #999;
}
.contact-form .second-container form .form-group textarea {
  width: 100%;
  min-height: 80px;
  resize: none;
  padding: 10px 20px;
  margin-top: -1px;
}
.contact-form .second-container form button {
  width: 200px;
  height: 50px;
  background: #1890ff;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  border: 0;
  position: relative;
  left: calc(50% - 100px);
  cursor: pointer;
}
.contact-form .second-container form button:hover {
  background: #333;
}
@media screen and (max-width: 800px) {
  .contact-form {
    width: 90vw;
 }
}
@media screen and (max-width: 700px) {
  .contact-form {
    flex-direction: column-reverse;
 }
  .contact-form > * {
    width: 100%;
 }
  .contact-form .first-container {
    padding: 40px 0;
 }
}

/* 
footer
------
*/
.ant-layout-footer {
  background: #f8f8f8 !important;
  color: #0000 !important;
  padding: 60px 0 !important;
  text-align: center;
}

.footer .logo {
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 0 20px;
}
.footer .sub {
  font-size: 15px;
  margin: 0 0 20px;
}
.footer .sub  #loc{
  color: #0000 !important;
}

.footer .fas {
  color: #1890ff;
  font-size: 28px;
  margin: 0 5px 0 0;
}
.footer .titleHolder #desc{
  color: #0000 !important;
}
.footer .logo a {
  color: #000;
}
.footer .logo img {
  height: 5%;
  width: 5%;
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
}

.footer .socials li {
  margin: 0 20px;
}

.footer .socials a {
  color: #000;
}

.footer .socials a:hover {
  color: #1890ff;
}

.footer .goTop:hover {
  opacity: 0.7;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

/* 
responsive
----------
*/
@media only screen and (max-width: 991px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1.14);
  }
}

@media only screen and (max-width: 767px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1);
  }

  .mobileVisible {
    display: block;
  }
  
  .mobileHidden {
    display: none;
  }
}

/* Animations */
@keyframes slideInFromLeft {
  0% {
      transform: translateX(-100%);
  }
  
  100% {
      transform: translateX(0);
  }
  }
  
  @keyframes slideInFromRight {
  0% {
      transform: translateX(100%);
  }
  
  100% {
      transform: translateX(0);
  }
  }
  
  @keyframes slideInFromTop {
  0% {
      transform: translateY(-100%);
  }
  
  100% {
      transform: translateX(0);
  }
  }
  
  @keyframes slideInFromBottom {
  0% {
      transform: translateY(100%);
  }
  
  100% {
      transform: translateX(0);
  }
  }


/* Partners Section */
.partners {
  text-align: center;
}

.partners__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(10rem, 100%), 1fr));
  gap: 0.5rem;
  align-items: center;
}

.partners__item {
  transition: none 300ms ease-in-out;
  transition-property: transform, opacity;
}

.partners__item:hover {
  transform: translateY(-0.1rem);
  opacity: 0.8;
}

.partner__img {
  width: 8.125rem;
  height: 3.125rem;
  object-fit: contain;
}
